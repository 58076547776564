<template>
  <!-- 委派任务 -->
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('603')"
    :dialogWidth="'550px'"
    :isCenter="true"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="save"
  >
    <div class="item">
      <label class="require_label" for>{{ t('1337') }}</label>
      <div class="item_bd static_item_bd">
        <span
          class="blue_word"
          v-show="versionList.length === 0"
          @click="openVersionLib('choose')"
          >{{ t('1338') }}</span
        >
        <ul v-show="versionList.length > 0">
          <li v-for="item in versionList" :key="item.versionId">
            <el-icon
              :size="20"
              color="#00a8ff"
              class="element_icon"
              style="margin-right: 6px"
            >
              <DocumentCopy />
            </el-icon>
            <div class="word_list">
              <div>{{ item.versionName }}</div>
              <div>{{ item.versionNumber }}</div>
              <div>
                {{
                  item.versionType === 'AIRPORT'
                    ? t("164")
                    : item.versionType === 'CONSOLE'
                    ? t("595")
                    : t("596")
                }}
              </div>
            </div>
            <span class="blue_word" @click="openVersionLib('change')"
              >{{ t('1339') }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="item">
      <label for>{{ t('130') }}</label>
      <div class="item_bd">
        <el-checkbox
          v-model="elForm.force"
          :label="t('1340')"
          @change="changeCheck"
        ></el-checkbox>
      </div>
    </div>
  </ele-dialog>
</template>

<script>
import { t } from '../../../../languages';
import { defineComponent, nextTick, onUnmounted, reactive, toRefs } from "vue";
import { ElMessage } from "element-plus";
import device from "@/network/device";
import emitter from "@/utils/mitt";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    updateType: {
      type: String,
      default: "",
    },
    updateNum: {
      type: Number,
      default: 0,
    },
  },
  emits: ["openVersionLib", "update:isVisible", "reloadTable"],
  setup(props, { emit }) {
    const state = reactive({
      elForm: {
        force: false,
      },
      versionList: [],
    });

    const forcedUpdate = localStorage.getItem("forcedUpdate");
    if (forcedUpdate !== null) {
      // localStorage.getItem取出为string
      if (forcedUpdate === "true") {
        state.elForm.force = true;
      } else {
        state.elForm.force = false;
      }
    }

    /* 修改强制更新复选框 */
    function changeCheck(flag) {
      localStorage.setItem("forcedUpdate", flag);
    }

    const setVersionList = (item) => {
      state.versionList = [];
      state.versionList.push(item);
      console.log(state.versionList);
    };

    // 绑定填入版本列表事件
    emitter.on("change-version", setVersionList);

    /**
     * 打开软件列表
     * type -- choose(选择版本)   change(更换版本)
     */
    function openVersionLib(type) {
      emit("openVersionLib");
      if (type === "change") {
        nextTick(() => {
          // 触发选中当前选中版本
          emitter.emit("check-version", state.versionList[0].versionId);
        });
      }
    }

    const { rowData, updateType,updateNum } = toRefs(props);

    /* 确认导入 */
    function save() {
      /* 确认导入 */
      device
        .updateVersion({
          firmwareType: updateNum.value + "",
          forceUpgrade: Number(state.elForm.force),
          libraryId: state.versionList[0].versionId,
          sn: rowData.value.nestSn,
        })
        .then((res) => {
          emit("update:isVisible", false);
          if (res.resultStatus) {
            ElMessage({
              message: t("751"),
              type: "success",
            });
          }
          emit("reloadTable");
        });
    }

    onUnmounted(() => {
      // 解绑
      emitter.off("check-version", setVersionList);
    });
    return {
      openVersionLib,
      save,
      changeCheck,
      ...toRefs(state),
    };
  },
});
</script>

<style scoped lang="less">
.item {
  > label {
    color: #36e5ff;
    font-size: 16px;
    padding-left: 17px;
  }
  .require_label {
    position: relative;
  }
  .require_label::before {
    content: "*";
    position: absolute;
    top: 3px;
    left: 0;
    color: #ee3131;
  }
  .item_bd {
    padding-left: 17px;
    /deep/ .el-checkbox__label {
      color: #fff;
    }
  }
  .static_item_bd {
    height: 94px;
    padding-top: 25px;
  }
  .blue_word {
    font-size: 16px;
    color: #00a8ff;
    cursor: pointer;
  }
  .blue_word:hover {
    opacity: 0.8;
  }
}
.word_list {
  display: inline-block;
  width: 390px;
  > div {
    display: inline-block;
    width: 33.33%;
    vertical-align: middle;
  }
}
</style>