<template>
  <!-- 更新记录 -->
  <div class="version_dialog">
    <ele-dialog
      :isVisible="isVisible"
      :dialogTitle="t('593')"
      :dialogWidth="'900px'"
      :isCenter="true"
      :hasFooter="false"
      @on-cancel="$emit('update:isVisible', false)"
      @on-add="$emit('update:isVisible', false)"
    >
      <div class="table_box">
        <ele-table
          :hasSelection="false"
          :serialNub="true"
          :tableColumnArray="tableColumnArray"
          :tableData="tableData"
          v-model:pageNo="queryForm.pageNo"
          v-model:pageSize="queryForm.pageSize"
          :total="queryForm.total"
          @handleNoChange="getData"
          @handleSizeChange="getData"
        >
          <template v-slot="{ prop, row }">
            <span v-if="prop === 'type'">
              <span v-if="row.type === '1'">{{ t('595') }}</span>
              <span v-else-if="row.type === '2'">{{ t('596') }}</span>
              <span v-else-if="row.type === '0'">{{ t('164') }}</span>
              <span v-else>-</span>
            </span>
          </template>
        </ele-table>
      </div>
    </ele-dialog>
  </div>
</template>

<script>
import { t } from '../../../../languages';
import { defineComponent, reactive, toRefs } from "vue";
import device from "@/network/device";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    sn: {
      type: String,
    },
  },
  data() {
    return {
      tableColumnArray: [
        { label: t("1341"), prop: "userName" },
        { label: t("606"), prop: "nestName" },
        { label: t("1342"), prop: "type" },
        { label: t("635"), prop: "version" },
        { label: t("607"), prop: "updateTime" },
      ],
    };
  },
  emits: ["update:isVisible"],
  setup(props) {
    const state = reactive({
      tableData: [],
      queryForm: {
        keyword: "",
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
    });

     const { sn } = toRefs(props);
    /* 获取表格数据 */
    async function getData(flag) {
      // 重置页数
      if (flag) {
        state.queryForm.pageNo = 1;
      }
      const tableData = await device.getVersionRecord({
        nestName: sn.value,
        pageNo: state.queryForm.pageNo,
        pageSize: state.queryForm.pageSize,
      });
      if (tableData.resultData.data instanceof Array) {
        state.tableData = tableData.resultData.data;
        state.queryForm.total = tableData.resultData.total;
      } else {
        state.tableData = [];
        state.queryForm.total = 0;
      }
    }

    getData();
    return {
      getData,
      ...toRefs(state),
    };
  },
});
</script>

<style scoped lang="less">
.table_box {
  margin-top: 20px;
  height: 65vh;
}
.version_dialog {
  /deep/ .common-dialog .el-dialog__body {
    padding: 20px 0 20px;
  }
}
.form_box {
  padding-left: 20px;
}
// 分页
/deep/ .el_pageination {
  bottom: 5rem;
}
</style>
