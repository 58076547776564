<template>
  <div class="container">
    <div class="header flex-sb">
      <div class="title flex">
        <h3 @click="jumpDevManage" class="hover_opacity">{{ t('163') }} /</h3>
        <h3>&nbsp;{{ t('167') }}</h3>
      </div>
      <el-button type="primary" @click="state.updaterecordShow = true"
        >{{ t('593') }}</el-button
      >
    </div>
    <div class="table_box">
      <ele-table
        :tableColumnArray="tableColumnArray"
        :tableData="state.tableData"
        :total="state.queryForm.total"
        @handleNoChange="getData"
        @handleSizeChange="getData"
      >
        <template v-slot:buttonHanlder>
          <el-table-column :label="t('594')" align="center" width="285">
            <template #default="scope">
              <div class="td_row">
                <div class="td_row_col4">{{ t('164') }}</div>
                <div class="td_row_col6">
                  {{ scope.row.typeAndVersion["0"] }}
                </div>
              </div>
              <div class="td_row">
                <div class="td_row_col4">{{ t('595') }}</div>
                <div class="td_row_col6">
                  {{ scope.row.typeAndVersion["1"] }}
                </div>
              </div>
              <div class="td_row">
                <div class="td_row_col4">{{ t('596') }}</div>
                <div class="td_row_col6">
                  {{ scope.row.typeAndVersion["2"] }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="t('597')" align="center">
            <template #default="scope">
              <span
                :class="[scope.row.status === '0' ? 'blue_btn' : 'green_btn']"
                >{{ scope.row.status === "0" ? t('598') : t('599') }}</span
              >
            </template>
          </el-table-column>
          <el-table-column :label="t('600')" align="center">
            <template #default="scope">
              <span class="blue_btn" @click="openConfirm(scope.row)">{{ t('601') }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="t('602')" align="center">
            <template #default="scope">
              <span class="blue_btn" @click="updateInline(scope.row)">
                {{ t('603') }}
              </span>
            </template>
          </el-table-column>
        </template>
      </ele-table>
    </div>
  </div>
  <!-- 遮罩 -->
  <div class="mask" v-show="state.showMask">
    <transition name="el-fade-in-linear">
      <div class="confirm_box">
        <div class="confirm_hd">
          <h4>
            {{
              state.refreshOrUpdate === "refresh"
                ? t('604')
                : t('605')
            }}
            <el-icon
              :size="18"
              class="element_icon hover_opacity"
              @click="state.showMask = false"
            >
              <Close />
            </el-icon>
          </h4>
        </div>
        <div class="confirm_bd">
          <div class="btn_group">
            <el-button type="primary" plain @click="chooseType(0,'AIRPORT')"
              >{{ t('164') }}</el-button
            >
            <el-button type="primary" plain @click="chooseType(1,'CONSOLE')"
              >{{ t('595') }}</el-button
            >
            <el-button type="primary" plain @click="chooseType(2,'GOOD_CALCULATION')"
              >{{ t('596') }}</el-button
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
  <inlineupdate
    v-if="state.inlineupdateShow"
    v-model:isVisible="state.inlineupdateShow"
    :updateType="state.updateType"
    :updateNum="state.updateNum"
    :rowData="state.rowData"
    @openVersionLib="openVersionLib"
    @reloadTable="getData"
  ></inlineupdate>
  <versionlibrary
    v-if="state.versionlibraryShow"
    v-model:isVisible="state.versionlibraryShow"
    :updateType="state.updateType"
  ></versionlibrary>
  <updaterecord
    :sn="route.query.sn"
    v-if="state.updaterecordShow"
    v-model:isVisible="state.updaterecordShow"
  ></updaterecord>
</template>

<script setup>
import { t } from '../../languages';
import { reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import device from "@/network/device";
import { ElMessage } from "element-plus";
import updaterecord from "./components/air-ver-componentsnents/update-record.vue";
import inlineupdate from "./components/air-ver-componentsnents/inline-update.vue";
import versionlibrary from "./components/air-ver-componentsnents/version-library.vue";

const route = useRoute();
console.log(route.query.sn);

const tableColumnArray = [
  { label: t("606"), prop: "nestName" },
  { label: t("607"), prop: "updateTime" },
];

// const tableDatas = [
//   {
//     createTime: null,
//     id: "1578943436972175361",
//     nestName: t("608"),
//     nestSn: "NEST03001",
//     status: "1",
//     typeAndVersion: {
//       0: "",
//       1: "",
//       2: "",
//     },
//     updateTime: "2022-10-09 11:00:25",
//   },
// ];
const state = reactive({
  tableData: [],
  checkedIds: [],
  queryForm: {
    snList: [],
    keyword: "",
    pageNo: 1,
    pageSize: 20,
    total: 0,
  },
  inlineupdateShow: false, //在线更新弹窗
  versionlibraryShow: false, //软件列表弹窗
  updaterecordShow: false, //更新记录弹窗
  rowData: {},
  showMask: false,
  refreshSn: "",
  refreshOrUpdate: "refresh",
  updateType: "",
  updateNum:"",
});

/* 获取表格数据 */
async function getData() {
  // 重置页数
  state.queryForm.snList.push(route.query.sn);
  const tableData = await device.getVersionList({
   
    nestSn: state.queryForm.snList,
    pageNo: state.queryForm.pageNo,
    pageSize: state.queryForm.pageSize,
  });
  state.tableData = tableData.resultData.data;
  state.queryForm.total = tableData.resultData.total;
  console.log(state.tableData);
}
getData();

/* 刷新 */
function chooseType(type,typeStr) {
  if (state.refreshOrUpdate === "refresh") {
    // 刷新
    device
      .refreshVersion({
        sn: state.refreshSn,
        versionType: type,
      })
      .then((res) => {
        if (res.resultStatus) {
          ElMessage({
            message: t("609"),
            type: "success",
          });
        }
        /* 刷新表格项 */
        const index = state.tableData.findIndex((item) => {
          return item.id === res.resultData.id;
        });
        if (index !== -1) {
          state.tableData[index] = res.resultData;
        }
      });
    state.showMask = false;
  } else {
    // 在线更新
    state.inlineupdateShow = true;
    state.showMask = false;
    state.updateType = typeStr;
    state.updateNum = type
  }
}

/* 打开确认框 */
function openConfirm(rowData) {
  state.refreshOrUpdate = "refresh";
  state.refreshSn = rowData.nestSn;
  state.showMask = true;
}

/* 在线更新 */
function updateInline(rowData) {
  state.refreshOrUpdate = "update";
  state.rowData = rowData;
  state.showMask = true;
}

/* 打开版本库弹窗 */
function openVersionLib() {
  state.versionlibraryShow = true;
}

// 跳转设备管理
const router = useRouter();
function jumpDevManage() {
  router.push("/airport");
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  height: 100%;
  background: rgba(0, 28, 49, 0.8);
  padding: 0 0.825rem;
  .header {
    height: 4.375rem;
    .title {
      color: #33ddf7;
    }
  }
  .table_box {
    .td_row {
      padding: 12px 0;
      // text-align: left;

      .td_row_col4 {
        display: inline-block;
        width: 4.375rem;
        vertical-align: middle;
      }
      .td_row_col6 {
        display: inline-block;
        width: 5.625rem;
        vertical-align: middle;
      }
    }
  }
}

/* 遮罩 */
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  text-align: center;
}
.mask::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
/* 确认弹窗 */
.confirm_box {
  display: inline-block;
  width: 400px;
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  vertical-align: middle;
  .confirm_hd {
    padding: 20px;
    color: rgb(48, 49, 51);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    > h4 {
      position: relative;
      .element_icon {
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
  }
  .confirm_bd {
    padding: 30px 0;
  }
}
.btn_group {
  text-align: center;
}
// 分页
/deep/ .el_pageination {
  right: 0.9375rem;
}
</style>