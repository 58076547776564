<template>
  <!-- 软件列表 -->
  <div class="version_dialog">
    <ele-dialog
      :isVisible="isVisible"
      :dialogTitle="t('157')"
      :dialogWidth="'900px'"
      :isCenter="true"
      @on-cancel="$emit('update:isVisible', false)"
      @on-add="save"
    >
      <div class="form_box clearfix">
        <div class="fl form_item input_item">
          <search-bar
            v-model:searchContent="queryForm.keyword"
            :searchPlaceholder="t('1343')"
            :width="'100%'"
            @on-search="getData(true)"
          ></search-bar>
        </div>
      </div>
      <el-checkbox-group v-model="checkedIds">
        <div class="table_box">
          <ele-table
            ref="multipleTable"
            :hasSelection="false"
            :serialNub="true"
            :tableColumnArray="tableColumnArray"
            :tableData="tableData"
            v-model:pageNo="queryForm.pageNo"
            v-model:pageSize="queryForm.pageSize"
            :total="queryForm.total"
            :fullHeight='fullHeight'
            @handleNoChange="getData"
            @handleSizeChange="getData"
          >

            <template v-slot:buttonHanlder>
              <el-table-column label align="center" width="180">
                <template #default="scope">
                  <el-checkbox
                    :label="scope.row.versionId"
                    @change="handleCheckedChange"
                    >{{}}</el-checkbox
                  >
                </template>
              </el-table-column>
            </template>
          </ele-table>
        </div>
      </el-checkbox-group>
    </ele-dialog>
  </div>
</template>

<script>
import { t } from '../../../../languages';
import { defineComponent, onUnmounted, reactive, ref, toRefs } from "vue";
import data from "@/network/data";
import { ElMessage } from "element-plus";
import emitter from "@/utils/mitt";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    updateType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableColumnArray: [
        { label: t("1344"), prop: "versionName" },
        { label: t("1342"), prop: "versionTypeName" },
        { label: t("635"), prop: "versionNum" },
        { label: t("412"), prop: "createTime" },
      ],
    };
  },
  emits: ["update:isVisible"],
  setup(props, { emit }) {
    const state = reactive({
      tableData: [],
      checkedIds: [],
      fullHeight:'65vh',
      queryForm: {
        keyword: "",
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
    });

  

    /* 获取表格数据 */
    async function getData(flag) {
      // 重置页数
      if (flag) {
        state.queryForm.pageNo = 1;
      }
      const tableData = await data.getVersionTable({
        type:props.updateType,
        queryInfo: state.queryForm.keyword,
        pageNo: state.queryForm.pageNo,
        pageSize: state.queryForm.pageSize,
      });
      if (tableData.resultData.data instanceof Array) {
        state.tableData = tableData.resultData.data;
        state.queryForm.total = tableData.resultData.total;
      } else {
        state.tableData = [];
        state.queryForm.total = 0;
      }
    }

    const multipleTable = ref(null);
    /* 勾选Checkbox */
    function handleCheckedChange(val) {
      if (val) {
        // 选中(只能选中一个)
        if (state.checkedIds.length !== 0) {
          const lastItem = state.checkedIds.pop();
          console.log(lastItem);
          state.checkedIds = [];
          state.checkedIds.push(lastItem);
        }
      } else {
        // 取消选中
        state.checkedIds = [];
      }
    }

    const checkVersion = (libId) => {
      state.checkedIds.push(libId);
    };

    /* 绑定默认勾选版本库事件 */
    emitter.on("check-version", checkVersion);

    /* 确认导入 */
    function save() {
      /* 确认导入 */
      if (state.checkedIds.length === 0) {
        ElMessage({
          message: t("1345"),
          type: "warning",
        });
      } else {
        const item = state.tableData.find((item) => {
          return item.versionId === state.checkedIds[0];
        });
        console.log(item);
        // 触发填入版本列表事件
        emitter.emit("change-version", item);
        emit("update:isVisible", false);
      }
    }

    onUnmounted(() => {
      // 解绑
      emitter.off("check-version", checkVersion);
    });

    getData();
    return {
      multipleTable,
      getData,
      handleCheckedChange,
      save,
      ...toRefs(state),
    };
  },
});
</script>

<style scoped lang="less">
.table_box {
  margin-top: 20px;
  height: 65vh;
}
.version_dialog {
  /deep/ .common-dialog .el-dialog__body {
    padding: 20px 0 20px;
  }
}
.form_box {
  padding-left: 20px;
}


</style>
